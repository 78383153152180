import { getImages, setColor, createUser, getCount, getTop, getLastTwentyMsg } from '@/api/painting';
import websocket from '@/utils/websocket';
export default {
  name: 'Pix',
  components: {},

  data() {
    return {
      version: '1.0',
      ctx: null,
      img: null,
      mouseUDSize: 50,
      canvasSize: [0, 0],
      canvasDefaultSize: [0, 0],
      showXy: {
        x: 0,
        y: 0
      },
      fdbs: 100,
      canvasXY: {
        x: 0,
        y: 0
      },
      clickXy: false,
      lastMoveLength: [0, 0],
      colorList: ['rgb(0, 0, 0)', 'rgb(85, 85, 85)', 'rgb(136, 136, 136)', 'rgb(205, 205, 205)', 'rgb(255, 255, 255)', 'rgb(255, 213, 188)', 'rgb(255, 183, 131)', 'rgb(182, 109, 61)', 'rgb(119, 67, 31)', 'rgb(252, 117, 16)', 'rgb(252, 168, 14)', 'rgb(253, 232, 23)', 'rgb(255, 244, 145)', 'rgb(190, 255, 64)', 'rgb(112, 221, 19)', 'rgb(49, 161, 23)', 'rgb(50, 182, 159)', 'rgb(136, 255, 243)', 'rgb(36, 181, 254)', 'rgb(18, 92, 199)', 'rgb(38, 41, 96)', 'rgb(139, 47, 168)', 'rgb(255, 89, 239)', 'rgb(255, 169, 217)', 'rgb(255, 100, 116)', 'rgb(240, 37, 35)', 'rgb(177, 18, 6)', 'rgb(116, 12, 0)'],
      nowSelectColor: 0,
      peopleList: [],
      nowRanking: false,
      user: {
        name: '',
        id: '',
        count: 0,
        notLogin: true
      },
      onlyLook: true,
      showInfoBox: true,
      onlineCount: 0,
      dialogVisible: true,
      nodeList: ['按住拖动画布', '鼠标滚轴缩放', '左键点击上色', '底下选颜色', '点击昵称修改昵称', '右键下载当前画布', '有时候点了没反应是因为网络波动', '移动端以前没玩过,所以移动端的适配并不好'],
      topList: [],
      msgList: [],
      msg: "" // doubleTouch: null

    };
  },

  created() {},

  mounted() {
    let canvas = this.$refs.canvas;
    this.ctx = canvas.getContext('2d');
    let that = this;
    let item = localStorage.getItem('user');

    if (item) {
      try {
        item = JSON.parse(item);
      } catch (e) {
        ElMessage.error('本地缓存出错了,尝试找回账号');
      }

      this.user = item;
      localStorage.setItem('token', item.id);
      websocket.Init(item.id, this.websocketMsg);
      this.onlyLook = false;
      this.showInfoBox = false;
      getCount(this.user.id).then(res => {
        if (res.code === 200) {
          this.user.count = res.data;
        }
      });
    } else {
      this.user.notLogin = true; //console.log('这里执行让用户设置个用户名然后向后台保存后获取id然后进行下边的操作,否则,只能看')
    }

    getLastTwentyMsg().then(res => {
      if (res.code === 200) {
        this.msgList = res.data;
        this.msgScrollToEnd();
      }
    });
    this.getTopList();
    getImages().then(res => {
      // res
      //读取完毕后输出结果
      let img = new Image();
      img.src = window.URL.createObjectURL(res.data);

      img.onload = () => {
        that.canvasDefaultSize = [img.width, img.height];
        that.canvasSize = [img.width, img.height];
        that.ctx.width = img.width;
        that.ctx.height = img.height; // this.drawCanvas()

        setTimeout(() => {
          this.ctx.drawImage(this.img, 0, 0);
        }, 10);
        canvas.addEventListener('mousewheel', this.handleScroll, true) || canvas.addEventListener("DOMMouseScroll", this.handleScroll, false);
        window.onmousemove = that.windowMouseMove;
        canvas.onmousemove = that.canvasMouseMove;
        canvas.onmousedown = that.canvasMouseDown;
        window.onmouseup = that.windowMouseUp;
      };

      that.img = img;
    });
    let version = localStorage.getItem('version');
    this.dialogVisible = !(version && version === this.version);
  },

  methods: {
    canvasClick(e) {
      let bb = this.fdbs / 100;
      let x = parseInt(e.offsetX / bb);
      let y = parseInt(e.offsetY / bb);

      if (x > -1 && y > -1 && x < this.canvasDefaultSize[0] && y < this.canvasDefaultSize[1]) {
        setColor({
          x,
          y,
          index: this.nowSelectColor
        }).then(res => {
          if (res.code === 200) {
            let lastItem = this.topList[0];
            this.topList.map(item => {
              if (item.name === this.user.name) {
                item.count++;

                if (lastItem.count < item.count) {
                  this.getTopList();
                }
              }

              lastItem = item;
            });
            this.drawToCanvas(x, y, this.nowSelectColor);
            this.user.count++;
          }
        });
      }
    },

    drawToCanvas(x, y, colorIndex) {
      this.ctx.fillStyle = this.colorList[colorIndex];
      this.ctx.fillRect(x, y, 1, 1);
    },

    //判断滚动方向
    handleScroll(e) {
      if (e.deltaY < 0 && this.fdbs < 2000) {
        this.fdbs += this.mouseUDSize;

        if (this.fdbs > 100) {
          this.canvasXY.x -= e.offsetX * this.mouseUDSize / this.fdbs;
          this.canvasXY.y -= e.offsetY * this.mouseUDSize / this.fdbs;
        }

        this.canvasSize[0] = this.canvasDefaultSize[0] * this.fdbs / 100;
        this.canvasSize[1] = this.canvasDefaultSize[1] * this.fdbs / 100;
      } else if (e.deltaY > 0 && this.fdbs > 50) {
        this.fdbs -= this.mouseUDSize;

        if (this.fdbs > 100) {
          this.canvasXY.x += e.offsetX * this.mouseUDSize / this.fdbs;
          this.canvasXY.y += e.offsetY * this.mouseUDSize / this.fdbs;
        }

        this.canvasSize[0] = this.canvasDefaultSize[0] * this.fdbs / 100;
        this.canvasSize[1] = this.canvasDefaultSize[1] * this.fdbs / 100;
      }
    },

    selectColor(i) {
      this.nowSelectColor = i;
    },

    rankingClick() {
      this.nowRanking = true;
    },

    chatClick() {
      this.nowRanking = false;
    },

    onlyLookClick() {
      this.onlyLook = true;
      this.showInfoBox = false;
      ElMessage({
        message: '未登录时,看看模式不能实时更新画布',
        grouping: true,
        duration: 0,
        showClose: true,
        type: 'warning'
      });
    },

    useName() {
      if (this.user.name.length > 0 && this.user.name.length < 8) {
        createUser(this.user.name).then(res => {
          if (res.code === 200) {
            this.user = res.data;
            this.user.notLogin = false;
            this.showInfoBox = false;
            this.onlyLook = false;
            localStorage.setItem('user', JSON.stringify(this.user));
            localStorage.setItem('token', this.user.id);
            websocket.Init(this.user.id, this.websocketMsg);
          }
        });
      } else if (this.user.name.length === 0) {
        ElMessage.error("请输入昵称");
      } else {
        ElMessage.error("昵称7个字以内");
      }
    },

    websocketMsg(data) {
      if (data.code === 200) {
        this.onlineCount = data.online;
      } else if (data.code === 201) {//console.log(data);
      } else if (data.code === 202) {
        this.drawToCanvas(data.pixel.x, data.pixel.y, data.pixel.colorIndex);
      } else if (data.code === 203) {
        ElMessage.error(data.msg);
      } else if (data.code === 204) {
        if (this.msgList.length > 20) {
          this.msgList.splice(0, 1);
        }

        this.msgList.push(data.message);
        this.msgScrollToEnd();
      }
    },

    onlyLookSwitch() {
      if (this.user.notLogin) {
        this.showInfoBox = true;
      } else {
        this.onlyLook = !this.onlyLook;
      }
    },

    explainClose() {
      this.dialogVisible = false;
      localStorage.setItem('version', this.version);
    },

    getTopList() {
      getTop().then(res => {
        if (res.code === 200) {
          this.topList = res.data;
        }
      });
    },

    sendMsg() {
      if (this.user.notLogin) {
        ElMessage.error("请先登录");
        return;
      }

      if (this.msg || this.msg.length < 20) {
        websocket.Send({
          msg: this.msg,
          code: 203
        });
        this.msg = "";
        this.$refs.msgInput.blur();
      } else if (!this.msg) {
        ElMessage.error("空的消息");
      } else {
        ElMessage.error("消息太长!");
      }
    },

    msgScrollToEnd() {
      let that = this;
      setTimeout(() => {
        that.$refs.msgDiv.scrollTop = that.$refs.msgDiv.scrollHeight;
      }, 20);
    },

    windowMouseUp(e) {
      //手指抬起重置双击放大数据
      //this.doubleTouch = null;
      // 双指return
      if (e.changedTouches && e.changedTouches.length > 1) {
        return;
      }

      let x, y;

      if (e.x && e.y) {
        x = e.x;
        y = e.y;
      } else {
        x = e.changedTouches[0].clientX;
        y = e.changedTouches[0].clientY;
      }

      if (this.clickXy) {
        if (Math.abs(this.clickXy[0] - x) < 5 && Math.abs(this.clickXy[1] === y) < 5) {
          if (!this.onlyLook) {
            this.canvasClick(e);
          }
        }

        this.clickXy = false;
      }
    },

    windowMouseMove(e) {
      //双指放大

      /*  if (e.changedTouches && e.changedTouches === 2) {
          console.log(e);
          console.log(e.changedTouches);
          console.log(e.changedTouches[0].clientY);
          let x1 = e.changedTouches[0].clientX;
          let y1 = e.changedTouches[0].clientY;
          let x2 = e.changedTouches[1].clientX;
          let y2 = e.changedTouches[1].clientY;
          if (this.doubleTouch) {
            let xLen = x1 - x2;
            let yLen = y1 - y2;
            let offsetX = xLen > 0 ? (x2 + xLen / 2) : (x1 - xLen / 2);
            let offsetY = yLen > 0 ? (y2 + yLen / 2) : (y1 - yLen / 2);
            if (
                //x距离增大
                (this.doubleTouch.x1 - this.doubleTouch.x2) > xLen &&
                //y距离增大
                (this.doubleTouch.y1 - this.doubleTouch.y2) > yLen
            ) {
                //放大
              this.handleScroll({
                deltaY: -100,
                offsetX,
                offsetY
              });
            } else if (
                //x距离减小
                (this.doubleTouch.x1 - this.doubleTouch.x2) < xLen &&
                //y距离减小
                (this.doubleTouch.y1 - this.doubleTouch.y2) < yLen
            ) {
              //缩小
              this.handleScroll({
                deltaY: 100,
                offsetX,
                offsetY
              });
            }
          }
          this.doubleTouch = {x1, y1, x2, y2}
        } else*/
      if (this.clickXy) {
        if (e.x && e.y) {
          this.canvasXY.x += e.x - this.lastXy[0];
          this.canvasXY.y += e.y - this.lastXy[1];
          this.lastXy = [e.x, e.y];
        } else {
          let x = e.changedTouches[0].clientX;
          let y = e.changedTouches[0].clientY;
          this.canvasXY.x += x - this.lastXy[0];
          this.canvasXY.y += y - this.lastXy[1];
          this.lastXy = [x, y];
        }
      }
    },

    canvasMouseDown(e) {
      // 双指return
      if (e.changedTouches && e.changedTouches.length > 1) {
        return;
      }

      if (e.x && e.y) {
        this.lastXy = this.clickXy = [e.x, e.y];
      } else {
        this.lastXy = this.clickXy = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
      }
    },

    canvasMouseMove(e) {
      this.showXy.x = parseInt(e.offsetX * 100 / this.fdbs);
      this.showXy.y = parseInt(e.offsetY * 100 / this.fdbs);
    }

  }
};