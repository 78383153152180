import request from '@/utils/request'

export function getImages() {
    return request({
        url: '/getImage',
        responseType: "blob",
        method: 'post'
    })
}

export function setColor({x, y, index}) {
    return request({
        url: '/setImage/' + x + '/' + y + '/' + index,
        method: 'post'
    })
}

export function createUser(name) {
    return request({
        url: '/user/createUser/' + name,
        method: 'post'
    })
}

export function getCount(id) {
    return request({
        url: "/getCount/" + id,
        method: "post"
    })
}

export function getTop() {
    return request({
        url: "/getTopList",
        method: "post"
    })
}

export function getLastTwentyMsg() {
    return request({
        url: "/msg/getLastTwentyMsg",
        method: "post"
    })
}
