import {ElMessage} from 'element-plus'
import serverConfig from "@/conf/host";

const url = (serverConfig.ssl ? 'wss://' : 'ws://') + (serverConfig.host ? serverConfig.host : '192.168.1.110:' + serverConfig.port) + '/pix/api/socket/';
let ws;
let tt;
let lockReconnect = false;//避免重复连接
// const clientId = localStorage.getItem("clientId");//缓存中取出客户端id
let token = "-1";//缓存中取出客户端id
//心跳检测
const heartCheck = {
    timeout: 1000 * 33,
    timeoutObj: null,
    serverTimeoutObj: null,
    start: function () {
        //console.log('开始心跳检测');
        let self = this;
        this.timeoutObj && clearInterval(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setInterval(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            //console.log('心跳检测...');
            ws.send('{"t":"' + token + '"}');
            self.serverTimeoutObj = setTimeout(function () {
                if (ws.readyState !== 1) {
                    ws.close();
                }
                // createWebSocket();
            }, self.timeout);

        }, this.timeout)
    }
};
const websocket = {
    Init: function (clientId, megFun) {
        token = clientId;
        if ("WebSocket" in window) {
            ws = new WebSocket(url + clientId);
        } else if ("MozWebSocket" in window) {
            // eslint-disable-next-line no-undef
            ws = new MozWebSocket(url + clientId);
        } else {
            ElMessage.error("您的浏览器不支持 WebSocket,无法实时更新画布")
            return;
        }
        ws.onmessage = function (e) {
            //console.log("接收消息:" + e.data)
            heartCheck.start()
            if (e.data === 'ok') {
                //心跳消息不做处理
            }
            if (megFun) {
                megFun(JSON.parse(e.data));
            }
        }

        ws.onclose = function () {
            //console.log("连接已关闭")
            reconnect(clientId);
        }

        ws.onopen = function () {
            //console.log("连接成功")
            heartCheck.start();
        }

        // eslint-disable-next-line no-unused-vars
        ws.onerror = function (e) {
            //console.log("数据传输发生错误", e);
            reconnect(clientId)
        }
    },
    Send: function (data) {
        if (data){
            let msg = JSON.stringify(data)
            ws.send(msg)
        }
    },
    getWebSocket() {
        return ws;
    },
    getStatus() {
        if (ws.readyState === 0) {
            return "未连接";
        } else if (ws.readyState === 1) {
            return "已连接";
        } else if (ws.readyState === 2) {
            return "连接正在关闭";
        } else if (ws.readyState === 3) {
            return "连接已关闭";
        }
    }
};

export default websocket;

function reconnect(sname) {
    if (lockReconnect) {
        return;
    }
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    tt && clearTimeout(tt);
    tt = setTimeout(function () {
        //console.log("执行断线重连...")
        websocket.Init(sname);
        lockReconnect = false;
    }, 4000);
}

