// const host = ""
const host = "pix.hsmyldk.top"
const ssl = host === "pix.hsmyldk.top";
const port = 8080;
const serverConfig = {
    host,
    ssl,
    port,
}
export default serverConfig;
