import axios from 'axios'//它是一个HTTP工具，用于与后端进行数据交互。
import {ElMessage} from 'element-plus'
import serverConfig from "@/conf/host";
//生产环境
//测试环境
//开发环境
const baseURL = (serverConfig.ssl ? "https://" : "") + serverConfig.host + '/pix/api';
//要是跨域的话，就请求本地服务器跳转到vue.config.js解决跨域问题

//c创建一个axios实例
const instance = axios.create({
    baseURL,
    timeout: 7000,
    headers: {}
})

//封装请求拦截器
instance.interceptors.request.use((config) => {
    //在请求被send出去之前，你可以在这里做一些事情
    //console.log('请求拦截器', config)
    //每次发送请求之前都要携带token鉴定身份去访问后端数据库
    let token = localStorage.getItem('token');
    if (token) {
        config.headers.token = token;
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

//封装响应拦截器
instance.interceptors.response.use((res) => {
    if (res.data.type === 'text/xml') {
        return res;
    } else if (res.status === 200) {
        if (res.data.code === 600) {
            ElMessage({
                message: res.data.msg,
                grouping: true,
                type: 'error',
            })
        }
        return res.data
    } else {
        ElMessage.error('请求出错')
    }
}, (error) => {
    return Promise.reject(error)
})


export default instance
