import {createRouter, createWebHashHistory} from 'vue-router'
import PixConfig from '@/views/PixConfig'
import Pix from '@/views/Pix'

const routes = [
    {
        path: '/',
        name: 'pix',
        component: Pix,
        title: '像素画'
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/config',
        name: 'config',
        component: PixConfig
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 全局后置钩子
router.afterEach(to => {
    // console.log(to);
    // 设置title
    document.title = to.title ? to.title : 'Hsmyldk';
})
export default router
