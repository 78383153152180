import {createApp} from 'vue'

// import ElementPlus from 'element-plus'
import App from './App.vue'
// main.ts
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import router from './router'
import store from './store'

let app = createApp(App).use(store).use(router).use(router);
// app.use(ElementPlus)
// app.use(ElementPlus, {size: 'small', zIndex: 3000})
app.mount('#app')

// Vue.prototype.$http = axios
