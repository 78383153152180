import { Setting, Edit } from "@element-plus/icons-vue";
export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    Setting,
    Edit
  },

  data() {
    return {
      showLoginBox: false,
      form: {
        name: '',
        pwd: '',
        isLogin: false
      },
      menuList: [{
        name: '封禁IP列表',
        url: ''
      }, {
        name: '查看最近点击数据',
        url: ''
      }]
    };
  },

  methods: {
    onSubmit() {
      console.log('submit!');

      if (this.form.name && this.form.pwd) {}
    },

    checkLogin() {
      return false;
    }

  }
};